import { isString } from '@yescapa-dev/ysc-api-js/modern'
import { YscError } from './YscError'
import { FORBIDDEN, BAD_GATEWAY, GATEWAY_TIMEOUT } from './errorFingerprints'

export interface OauthErrorResponse {
  error: string
  error_description: string
}

export interface ApiErrorResponseWithAll {
  __all__: string
  [fieldName: string]: string // field_name is dynamic
}
/**
 * List of different error formats
 */
export type ApiErrorResponse =
  | {
    // V4 format
    [fieldName: string]: string[] // field_name is dynamic
    non_field_errors: string[] // non_field_errors occurs when an error is not directly linked to the operation. Ex: phone is not certified for an operation where `phone` isn't part of the payload
  }
  // Following are format on older endpoint
  | {
    [fieldName: string]: false // field_name is dynamic
  }
  | ApiErrorResponseWithAll
  | {
    __all__: string[] // We can not represent the type { __all__: string | string[], [fieldName: string] } in TS currently, so this is a bad workaround
  }
  | {
    error: {
      [fieldName: string]: string // field_name is dynamic
    }
  }
  | OauthErrorResponse
  | {
    detail: string
  }
  | string[]
  | null
  | undefined
  | string // trustedshop error
/**
 * @typedef {object} FieldError - A formatted error field
 * @property {object} label - A summary of the reason, mainly the property name
 * @property {string} label.text - the text or the key of the toast's title
 * @property {boolean} label.needTranslation - a boolean to know if it needs to be translated by i18n
 * @property {object} description - An indication for the user
 * @property {string} description.text - the tedescriptionxt or the key of the toast's content
 * @property {boolean} description.needTranslation - a boolean to know if it needs to be translated by i18n
 */

export interface FieldError {
  label: {
    text: string
    needTranslation: boolean
    translationParams?: {
      [key: string]: string | number
    }
  }
  description?: {
    text: string
    needTranslation: boolean
    translationParams?: {
      [key: string]: string | number
    }
  }
}

export interface YscApiErrorParams {
  apiStatus: number
  apiMethod?: string
  apiEndpoint?: string
  apiURL?: string
  apiResponseErrorData: ApiErrorResponse
  apiPayloadData: unknown
  apiParamsData: unknown
  locale: string
  route: string
  name: string
}

const apiResponseStatusToOverride = {
  403: FORBIDDEN,
  502: BAD_GATEWAY,
  504: GATEWAY_TIMEOUT,
}

export class YscApiError extends YscError {
  apiStatus: number
  apiMethod?: string
  apiEndpoint?: string
  apiURL?: string
  apiResponseErrorData: ApiErrorResponse
  apiPayloadData: unknown
  apiParamsData: unknown
  _fields: null | FieldError[]
  _defaultDescription: { text: string, needTranslation: boolean } | null

  constructor({
    apiStatus,
    apiMethod,
    apiEndpoint,
    apiResponseErrorData,
    apiPayloadData,
    apiParamsData,
    apiURL,
    locale,
    route,
    name,
  }: YscApiErrorParams) {
    super({ message: `API ${apiStatus} ${apiMethod} ${apiEndpoint}`, locale, route, name })

    this.apiStatus = apiStatus
    this.apiMethod = apiMethod
    this.apiEndpoint = apiEndpoint
    this.apiPayloadData = apiPayloadData
    this.apiParamsData = apiParamsData
    this.apiURL = apiURL
    this.apiResponseErrorData = apiResponseErrorData
    this._fields = null
    this._defaultDescription = null

    // Automatically flag responses in apiResponseStatusToOverride
    if (apiStatus in apiResponseStatusToOverride) {
      this.fingerprint = apiResponseStatusToOverride[apiStatus as keyof typeof apiResponseStatusToOverride]
    }
  }

  get fingerprint() {
    return super.fingerprint
  }

  // Prevent flag to be overriden by inherited classes
  set fingerprint(value) {
    if (Object.values(apiResponseStatusToOverride).includes(this.fingerprint)) {
      return
    }

    super.fingerprint = value
  }

  // Can be usefull in inherited classes to override fields
  get defaultLabel() {
    return { text: 'commons.errors.error', needTranslation: true }
  }

  get defaultDescription() {
    if (this._defaultDescription) {
      return this._defaultDescription
    }

    return { text: 'commons.errors.unknown', needTranslation: true }
  }

  set defaultDescription(value: NonNullable<FieldError['description']>) {
    this._defaultDescription = value
  }

  get fields(): FieldError[] {
    // See setter
    if (this._fields) {
      return this._fields
    }

    if (
      (typeof this.apiResponseErrorData !== 'object' && !Array.isArray(this.apiResponseErrorData))
      || this.apiResponseErrorData === null
      || this.apiResponseErrorData === undefined
    ) {
      return [{ label: this.defaultLabel }]
    }

    if (Object.keys(this.apiResponseErrorData).length === 0) {
      return [{ label: this.defaultLabel, description: this.defaultDescription }]
    }

    if (Array.isArray(this.apiResponseErrorData)) {
      return [{ label: this.defaultLabel, description: { text: this.apiResponseErrorData.join(' '), needTranslation: false } }]
    }

    if ('detail' in this.apiResponseErrorData && isString(this.apiResponseErrorData.detail)) {
      return [{ label: this.defaultLabel, description: { text: this.apiResponseErrorData.detail, needTranslation: false } }]
    }

    const formatFields = (fieldEntries: [string, string | string[]][]) =>
      fieldEntries.reduce((acc: FieldError[], [field, value]) => {
        if (field === 'non_field_errors' || field === '__all__') {
          if (Array.isArray(value)) {
            acc.push(
              ...value.map(text => ({
                label: this.defaultLabel,
                description: isNaN(Number.parseInt(text)) ? { text, needTranslation: false } : this.defaultDescription,
              })),
            )
            return acc
          }

          acc.push({
            label: this.defaultLabel,
            description: isNaN(Number.parseInt(value)) ? { text: value, needTranslation: false } : this.defaultDescription,
          })
          return acc
        }

        acc.push({
          label: { text: field, needTranslation: false },
          description: { text: Array.isArray(value) ? value[0] : value, needTranslation: false },
        })
        return acc
      }, [])

    if ('error' in this.apiResponseErrorData) {
      if (isString(this.apiResponseErrorData.error)) {
        const text
          = 'error_description' in this.apiResponseErrorData && isString(this.apiResponseErrorData.error_description)
            ? this.apiResponseErrorData.error_description
            : this.apiResponseErrorData.error
        return [{ label: this.defaultLabel, description: { text, needTranslation: false } }]
      }

      return formatFields(Object.entries(this.apiResponseErrorData.error))
    }

    return formatFields(Object.entries(this.apiResponseErrorData))
  }

  /**
   * @params {FieldError[]} value - completly override fields
   */
  set fields(value: FieldError[]) {
    this._fields = value
  }

  get sentryAdditionalData() {
    return {
      ...super.sentryAdditionalData,
      fields: this.fields,
      apiStatus: this.apiStatus,
      apiMethod: this.apiMethod,
      apiEndpoint: this.apiEndpoint,
      apiPayloadData: this.apiPayloadData,
      apiParamsData: this.apiParamsData,
      apiURL: this.apiURL,
    }
  }
}
