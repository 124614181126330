<script setup lang="ts">
interface Props {
  firstName?: string
  picture?: string | null
  radius?: string | number
  isBestOwner?: boolean | null
  isVerified?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  firstName: undefined,
  picture: undefined,
  radius: 35,
  isBestOwner: false,
  isVerified: false,
})

const didLoad = ref(false)

const width = computed(() => {
  return +props.radius * 2
})

const firstLetter = computed(() => {
  if (!props.firstName) {
    return null
  }
  return props.firstName.substring(0, 1).toUpperCase()
})

watch(() => props.picture, () => {
  if (import.meta.server) {
    return
  }
  didLoad.value = false
  if (props.picture && !props.picture.includes('no-profile')) {
    const img = new Image()
    img.onerror = () => {
      didLoad.value = false
    }
    img.onload = () => {
      didLoad.value = true
    }
    img.src = props.picture
  }
}, { immediate: true })
</script>

<template>
  <div
    class="relative inline-block leading-none"
    style="font-size: 0px"
  >
    <div class="inline-block overflow-hidden rounded-full bg-gray-100">
      <transition
        v-if="props.picture || !props.firstName"
        name="fade"
        mode="out-in"
      >
        <div
          v-if="didLoad"
          :style="{
            width: `${width}px`,
            height: `${width}px`,
            backgroundImage: `url(${props.picture})`,
          }"
          class="bg-cover bg-center"
        />
        <img
          v-else
          src="~assets/img/no-profile.svg"
          :alt="props.firstName"
          :width="width"
          :height="width"
          class="max-h-full max-w-full"
        >
      </transition>
      <div
        v-else
        :style="{
          width: `${width}px`,
          height: `${width}px`,
          fontSize: `${width/2}px`,
        }"
        class="bg-peacock-700 inline-flex justify-center items-center text-white font-semibold"
      >
        {{ firstLetter }}
      </div>
    </div>
    <div
      v-if="props.isBestOwner || props.isVerified"
      class="absolute top-0 rounded-full bg-white"
      :style="{
        padding: '2px',
        width: 'max(18px, 25%)',
        height: 'max(18px, 25%)',
        right: 'clamp(-4px, calc((18px - 25%) * -1), 0px)',
        top: 'clamp(-4px, calc((18px - 25%) * -1), 0px)',
      }"
    >
      <YscIconsMedal
        v-if="props.isBestOwner"
        class="h-full w-full"
      />
      <YscIconsCheckCircleSolid
        v-else
        class="h-full w-full text-pink-500"
      />
    </div>
  </div>
</template>
