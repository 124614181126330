import { BOOKING_PRICE_ERROR_CODES, isString } from '@yescapa-dev/ysc-api-js/modern'
import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import {
  CAMPER_PRICE_CLOSED_DAYS,
  CAMPER_PRICE_INVALID_DOCUMENTS,
  CAMPER_PRICE_INVERTED_DATES,
  CAMPER_PRICE_KM_UNLIMITED_NOT_ALLOWED,
  CAMPER_PRICE_MIN_DAYS_REQUIRED,
  CAMPER_PRICE_NOT_AVAILABLE,
  CAMPER_PRICE_UNKNOWN,
} from './errorFingerprints'

export class YscApiCamperPriceError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    const { apiResponseErrorData } = args

    if (!apiResponseErrorData || typeof apiResponseErrorData !== 'object' || !('__all__' in apiResponseErrorData) || !Array.isArray(apiResponseErrorData.__all__)) {
      return
    }

    const { __all__ } = apiResponseErrorData

    // not sure if the array will always have one item
    const errorCode = __all__[0]

    const { INVERTED_DATES, INVALID_DOCUMENTS_LESS_72, KM_UNLIMITED_NOT_ALLOWED, NOT_AVAILABLE, MIN_DAYS_REQUIRED, CLOSED_DAYS, INVALID_DOCUMENTS_LESS_24 }
      = BOOKING_PRICE_ERROR_CODES

    if (errorCode === INVERTED_DATES) {
      this.fingerprint = CAMPER_PRICE_INVERTED_DATES
      this.defaultDescription = { text: 'data.booking_request.errors.inverted_dates.description', needTranslation: true }
      return
    }

    if (errorCode === INVALID_DOCUMENTS_LESS_72 || errorCode === INVALID_DOCUMENTS_LESS_24) {
      this.fingerprint = CAMPER_PRICE_INVALID_DOCUMENTS
      this.defaultDescription = {
        text: 'data.booking_request.errors.documents_not_validated.description_dynamic',
        needTranslation: true,
        translationParams: {
          hour_number:
            errorCode === INVALID_DOCUMENTS_LESS_72
              ? 72
              : 24,
        },
      }
      return
    }

    if (errorCode === KM_UNLIMITED_NOT_ALLOWED) {
      this.fingerprint = CAMPER_PRICE_KM_UNLIMITED_NOT_ALLOWED
      this.defaultDescription = { text: 'data.booking_request.errors.km_unlimited_not_allowed.description', needTranslation: true }
      return
    }

    if (errorCode === NOT_AVAILABLE) {
      this.fingerprint = CAMPER_PRICE_NOT_AVAILABLE
      this.defaultDescription = { text: 'data.booking_request.errors.not_available.description', needTranslation: true }
      return
    }

    if (errorCode === MIN_DAYS_REQUIRED) {
      this.fingerprint = CAMPER_PRICE_MIN_DAYS_REQUIRED
      this.defaultDescription = {
        text: 'data.booking_request.errors.booking_too_short.description_dynamic',
        needTranslation: true,
        translationParams: {
          count_number:
            'min_length' in apiResponseErrorData && isString(apiResponseErrorData.min_length)
              ? Number.parseInt(apiResponseErrorData.min_length)
              : 0,
        },
      }
      return
    }

    if (errorCode === CLOSED_DAYS) {
      this.fingerprint = CAMPER_PRICE_CLOSED_DAYS
      this.defaultDescription = { text: 'data.booking_request.errors.closed_day.description', needTranslation: true }
      return
    }

    this.fingerprint = CAMPER_PRICE_UNKNOWN
  }
}
